<template>
  <FormInputSelectWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="blendOptions" :value="value" @input="$emit('input', $event)" />
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';

export default {
  mixins: [formInputWidgetMixin],
  components: {
    FormInputSelectWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputSelectWidget.vue'),
  },
  props: {
    blends: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      allBlends: null,
    };
  },
  computed: {
    completeBlends () {
      return this.blends || this.allBlends || [];
    },
    blendOptions () {
      return this.completeBlends.map(p => {
        p.value = p.id;
        p.label = p.name;

        return p;
      });
    },
  },
  mounted () {
    if (null === this.blends) {
      this.$api.listBlends()
        .then(res => {
          this.allBlends = res.data.blends;
        })
        .catch(this.$log.error)
      ;
    }
  },
};

</script>
