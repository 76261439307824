<template>
  <DialogModal btnLabel="Aggiungi" title="Aggiungi certificazione" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100">
        <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
      </div>
    </template>

    <template #default>
      <SelectCertificationWidget
        v-model="certification"
        label="Certificazione da aggiungere"
        :certifications="certifications"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import SelectCertificationWidget from '@/views/components/Form/Widgets/SelectCertificationWidget.vue';

export default {
  components: { DialogModal, SelectCertificationWidget },
  props: {
    certifications: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      certification: null,
    };
  },
  methods: {
    closeModal (close) {
      this.$emit('certificationSelected', this.certification);
      close();
    },
  },
};

</script>
