<template>
  <main v-if="wine">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ wine.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.wines.edit', params: { id: wine.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div class="container">
      <div class="row">
        <div class="col mb-3">
          <strong>Nome del vino</strong>:
          {{ wine.name }}
        </div>
        <div class="col mb-3">
          <strong>Produttore</strong>:
          <router-link :to="{ name: 'suppliers.producers.show', params: { id: wine.producerId } }">{{ wine.producerName }}</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col mb-3">
          <strong>Tipologia</strong>:
          <router-link :to="{ name: 'inventory.wine-types.show', params: { id: wine.typeId } }">{{ wine.typeName }}</router-link>
          <span v-if="wine.sparklingWine"> [Spumante]</span>
        </div>
      </div>
      <div class="row">
        <div class="col mb-3">
          <strong>Certificazioni</strong> <button v-if="missingCertifications.length > 0" class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="$openModal('addCertification')">+</button>
          <br>
          <template v-if="wine.certifications.length === 0">Nessuna certificazione</template>
          <template v-else>
            <template v-for="certification in wine.certifications">
              <div :key="'certification-' + certification.id">
                <button class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="removeCertification(certification.id)">-</button> {{ certification.name }}
              </div>
            </template>
          </template>
        </div>
        <div class="col mb-3">
          <strong>Tags</strong> <button v-if="missingTags.length > 0" class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="$openModal('addTag')">+</button>
          <br>
          <template v-if="wine.tags.length === 0">Nessun tag associato</template>
          <template v-else>
            <template v-for="tag in wine.tags">
              <div :key="'tag-' + tag.id">
                <button class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="removeTag(tag.id)">-</button> {{ tag.name }}
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col mb-3">
          <strong>Varietà di uva principali</strong> <button v-if="missingBlends.length > 0" class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="$openModal('addMainBlend')">+</button>
          <br>
          <template v-if="wine.mainBlends.length === 0">Nessuna varietà di uva principale associata</template>
          <template v-else>
            <template v-for="mainBlend in wine.mainBlends">
              <div :key="'mainBlend-' + mainBlend.id">
                <button class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="removeMainBlend(mainBlend.id)">-</button> {{ mainBlend.name }}
              </div>
            </template>
          </template>
        </div>
        <div class="col mb-3">
          <strong>Varietà di uva secondarie</strong> <button v-if="missingBlends.length > 0" class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="$openModal('addSecondaryBlend')">+</button>
          <br>
          <template v-if="wine.secondaryBlends.length === 0">Nessuna varietà di uva secondaria associata</template>
          <template v-else>
            <template v-for="secondaryBlend in wine.secondaryBlends">
              <div :key="'secondaryBlend-' + secondaryBlend.id">
                <button class="btn btn-outline-secondary btn-sm" type="button" @click.prevent="removeSecondaryBlend(secondaryBlend.id)">-</button> {{ secondaryBlend.name }}
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <SelectBlendModal v-if="$modals.addMainBlend" title="Aggiungi varietà di uva principale" :blends="missingBlends" @close="$closeModal('addMainBlend')" @blendSelected="addMainBlend" />
    <SelectBlendModal v-if="$modals.addSecondaryBlend" title="Aggiungi varietà di uva secondaria" :blends="missingBlends" @close="$closeModal('addSecondaryBlend')" @blendSelected="addSecondaryBlend" />
    <SelectCertificationModal v-if="$modals.addCertification" :certifications="missingCertifications" @close="$closeModal('addCertification')" @certificationSelected="addCertification" />
    <SelectTagModal v-if="$modals.addTag" :tags="missingTags" @close="$closeModal('addTag')" @tagSelected="addTag" />

    <div v-if="products" class="mt-5">
      <div class="row align-items-center justify-content-between">
        <div class="col mb-3">
          <h4 class="mb-0">Prodotti a catalogo di questo vino</h4>
        </div>

        <div class="col-auto mb-3">
          <button class="btn btn-outline-primary" @click.prevent="addProduct">Aggiungi</button>
        </div>
      </div>

      <AwlTable v-if="products.length > 0" :heading="tableProductsHeadings" :rows="products" striped>
        <template #column-key-packageName="{ row }">
          {{ row.packageName }} {{ row.packageMaterial }}<br>
          {{ row.packageVolume / 1000 }}L
        </template>

        <template #column-key-purchasePrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #column-key-defaultSellingPrice="{ value }">
          {{ value / 100 }}€
        </template>

        <template #actions-content="{ row }">
          <ActionShow class="ms-2" :to="{ name: 'inventory.products.show', params: { id: row.id } }" />
        </template>
      </AwlTable>
      <p v-else>Nessun prodotto presente per questo vino</p>

      <ProductModal v-if="$modals.add" :value="newProduct" @close="$closeModal('add')" @submitForm="saveNewProduct" />
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import ModalsManagerMixin from '@/libs/Modals/mixins/manager.js';

import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import ProductModal from '@/views/components/Modals/ProductModal.vue';
import SelectBlendModal from '@/views/components/Modals/SelectBlendModal.vue';
import SelectCertificationModal from '@/views/components/Modals/SelectCertificationModal.vue';
import SelectTagModal from '@/views/components/Modals/SelectTagModal.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  Promise.all([
    $api.fetchWine(to.params.id),
    $api.getAllWinePackages({
      wine: to.params.id,
    }),
    $api.listBlends(),
    $api.listCertifications(),
    $api.listTags(),
  ])
    .then(res => next(vm => {
      vm.wine = res[0]?.data;
      vm.products = res[1]?.data?.products || [];
      vm.allBlends = res[2]?.data?.blends || [];
      vm.allCertifications = res[3]?.data?.certifications || [];
      vm.allTags = res[4]?.data?.tags || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle, AwlTable, ActionShow, ProductModal, SelectBlendModal, SelectCertificationModal, SelectTagModal },
  mixins: [ ModalsManagerMixin ],
  data () {
    return {
      loading: false,
      wine: null,
      products: null,
      allCertifications: null,
      allTags: null,
      allBlends: null,
    };
  },
  computed: {
    tableProductsHeadings () {
      return [
        { key: 'vintageName', label: 'Anno' },
        { key: 'packageName', label: 'Contenitore' },
        { key: 'weight', label: 'Peso' },
        { key: 'alcoholContent', label: 'Gradazione alcolica' },
        { key: 'quantity', label: 'Quantità' },
        { key: 'purchasePrice', label: 'Prezzo acquisto' },
        { key: 'defaultSellingPrice', label: 'Prezzo vendita' },
      ];
    },
    newProduct () {
      return {
        wine: this.wine.id,
        package: null,
        year: null,
        quantity: null,
        purchasePrice: null,
        defaultSellingPrice: null,
      };
    },
    missingBlends () {
      return (this.allBlends || []).filter(ab => !this.wine.mainBlends.find(mb => mb.id === ab.id) && !this.wine.secondaryBlends.find(sb => sb.id === ab.id));
    },
    missingCertifications () {
      return (this.allCertifications || []).filter(ac => !this.wine.certifications.find(c => c.id === ac.id));
    },
    missingTags () {
      return (this.allTags || []).filter(at => !this.wine.tags.find(t => t.id === at.id));
    },
  },
  methods: {
    addProduct () {
      this.$openModal('add');
    },
    saveNewProduct (product) {
      this.$api.createWinePackage(product)
        .then(() => {
          return this.$api.getAllWinePackages({
            wine: this.wine.id,
          });
        })
        .then((res) => {
          this.products = res.data.products;
          this.$closeModal('add');
        })
      ;
    },
    addCertification (certificationId) {
      this.$api.addCertificationToWine(this.wine.id, certificationId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    removeCertification (certificationId) {
      this.$api.removeCertificationFromWine(this.wine.id, certificationId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    addTag (tagId) {
      this.$api.addTagToWine(this.wine.id, tagId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    removeTag (tagId) {
      this.$api.removeTagFromWine(this.wine.id, tagId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    addMainBlend (certificationId) {
      this.$api.addMainBlendToWine(this.wine.id, certificationId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    removeMainBlend (blendId) {
      this.$api.removeMainBlendFromWine(this.wine.id, blendId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    addSecondaryBlend (blendId) {
      this.$api.addSecondaryBlendToWine(this.wine.id, blendId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
    removeSecondaryBlend (blendId) {
      this.$api.removeSecondaryBlendFromWine(this.wine.id, blendId)
        .then((res) => {
          this.wine = res.data;
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
