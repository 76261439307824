<template>
  <DialogModal btnLabel="Aggiungi" :title="title" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100">
        <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
      </div>
    </template>

    <template #default>
      <SelectBlendWidget
        v-model="blend"
        label="Varietà di uva da aggiungere"
        :blends="blends"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import SelectBlendWidget from '@/views/components/Form/Widgets/SelectBlendWidget.vue';

export default {
  components: { DialogModal, SelectBlendWidget },
  props: {
    blends: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      blend: null,
    };
  },
  methods: {
    closeModal (close) {
      this.$emit('blendSelected', this.blend);
      close();
    },
  },
};

</script>
