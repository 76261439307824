<template>
  <DialogModal btnLabel="Aggiungi" title="Aggiungi tag" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div class="w-100">
        <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
      </div>
    </template>

    <template #default>
      <SelectTagWidget
        v-model="tag"
        label="Certificazione da aggiungere"
        :tags="tags"
      />
    </template>
  </DialogModal>
</template>

<script>

import DialogModal from '@/libs/Modals/components/DialogModal.vue';
import SelectTagWidget from '@/views/components/Form/Widgets/SelectTagWidget.vue';

export default {
  components: { DialogModal, SelectTagWidget },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      tag: null,
    };
  },
  methods: {
    closeModal (close) {
      this.$emit('tagSelected', this.tag);
      close();
    },
  },
};

</script>
